function stickyHeader() {
  var scrollPos = $(this).scrollTop();
  if (scrollPos > 0) {
    $(".section-header").addClass("section-header--active");
  } else {
    $(".section-header").removeClass("section-header--active");
  }
}

$(document).on("scroll", function() {
  stickyHeader();
});

$(window).on("load", function() {
  stickyHeader();
});
