// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") ||
      location.hostname == this.hostname
    ) {
      var hashStr = this.hash.slice(1);
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + hashStr + "]");

      if (target.length) {
        $("html,body").animate({ scrollTop: target.offset().top - 64 }, 1000);
        window.location.hash = hashStr;
        return false;
      }
    }
  });
