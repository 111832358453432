$(".language").on("click", function() {
  var slug = $(this).attr("data-language-slug");
  var title = $(this).attr("data-language-title");

  $(".overlay-mask").addClass("overlay-mask--active");

  $(".coming-soon__flag").attr(
    "style",
    'background-image: url("img/heart-flag-' + slug + '.svg")'
  );

  $(".coming-soon h1").text("Doats " + title);
  $(".coming-soon p").text(
    "We will be launching Doats " +
      title +
      " soon, check back for more detials."
  );
});

$("body").on("click", ".overlay-mask .icon-close", function() {
  $(".overlay-mask").removeClass("overlay-mask--active");
});
